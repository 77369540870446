






















@import "../scss/importall.scss";

.block-quote {
  @extend %h3;
  margin: 2em 0;
}

.block-quote__footer {
  @extend %text;

  display: flex;
  align-items: center;
  margin: 1em 0 0 0;
  color: var(--category-color);
}

.block-quote__line {
  height: 2px;
  width: 6em;
  margin-right: 1em;
  background-color: var(--category-color);
}
































@import "../scss/importall.scss";

.content-image {
  display: block;
  margin: 4rem 0;
}

.content-image__caption {
  @extend %text-small;
  padding-top: 1em;
}

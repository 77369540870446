



































@import "../scss/importall.scss";

.podcast-module {
  padding: 0 1rem;
  @include mq-tablet {
    display: flex;
  }
}

.podcast-module__content {
  color: #fff;
  padding: 5rem 3rem;

  @include mq-tablet {
    padding: 7rem 3rem;
    flex: 0 0 50%;
    width: 50%;
  }
}

.podcast-module__image {
  position: relative;

  @include mq-tablet {
    flex: 0 0 50%;
    width: 50%;
    padding-bottom: 0;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65%;
    height: auto;
    border-radius: 0.5rem;
    box-shadow: 0 0.3rem 0.6rem rgb(0 0 0 / 10%);
    transition: box-shadow 0.2s;
  }

  a:hover img,
  a:focus img {
    box-shadow: 0 0.5rem 0.7rem rgb(0 0 0 / 20%);
  }
}

.podcast-module__title {
  @extend %h2;
  margin: 0 0 0.5em 0;
}

.podcast-module__text {
  @extend %text;
  margin: 0 0 1.5em 0;
}















.close-icon {
  fill: #000;
  display: block;
}

@mixin flexgrid-cols($cols, $col-gap) {
  > * {
    flex: 0 0 100%;
    max-width: calc((100% / #{$cols}) - #{$col-gap});
  }
}

@mixin flexgrid($row-gap: 0, $col-gap: 0, $cols: 0) {
  display: flex;
  flex-wrap: wrap;
  margin: (-$row-gap) 0 0 (-$col-gap);

  > * {
    margin: $row-gap 0 0 $col-gap;
  }

  @if $cols > 0 {
    @include flexgrid-cols($cols, $col-gap);
  }
}

// 414px: 1.55rem = 16px (roughly)
// 1280px: 1.25rem = 16px

%h0 {
  font-family: 'Baton Turbo', sans-serif;
  font-size: 4.65rem;
  line-height: 1.25;
  font-weight: 400;

  @include mq-tablet {
    font-size: 5.46875rem;
    line-height: 1.2;
  }
}

%h1 {
  font-family: 'Baton Turbo', sans-serif;
  font-size: 3.875rem;
  line-height: 1.3;
  font-weight: 400;

  @include mq-tablet {
    font-size: 3.90625rem;
    line-height: 1.28;
  }
}

%h2 {
  font-family: 'Baton Turbo', sans-serif;
  font-size: 3.1rem;
  line-height: 1.3125;
  font-weight: 400;

  @include mq-tablet {
    font-size: 3.125rem;
    line-height: 1.4;
  }
}

%h3 {
  font-family: 'Baton Turbo', sans-serif;
  font-size: 2.325rem;
  line-height: 1.5;
  font-weight: 400;

  @include mq-tablet {
    font-size: 2.5rem;
    line-height: 1.4375;
  }
}

%text-excerpt-big {
  font-family: 'Baton Turbo', sans-serif;
  font-size: 2.325rem;
  line-height: 1.5;
  font-weight: 400;

  @include mq-tablet {
    font-size: 1.875rem;
    line-height: 1.5;
  }
}

%text-excerpt {
  font-family: 'Baton Turbo', sans-serif;
  font-size: 2.325rem;
  line-height: 1.5;
  font-weight: 400;

  @include mq-tablet {
    font-size: 1.5625rem;
    line-height: 1.7;
  }
}

%text {
  font-family: 'Baton Turbo', sans-serif;
  font-size: 1.74375rem;
  line-height: 1.7777;
  font-weight: 400;

  @include mq-tablet {
    font-size: 1.40625rem;
    line-height: 1.6666;
  }
}

%text-small {
  font-family: 'Baton Turbo', sans-serif;
  font-size: 1.35625rem;
  line-height: 1.357;
  font-weight: 400;

  @include mq-tablet {
    font-size: 1.09375rem;
    line-height: 1.714;
  }
}

































































@import "../scss/importall.scss";

.base-teaser {
  @extend %text;

  display: block;
  color: #fff;
  text-decoration: none;
}

.base-teaser__image img {
  display: block;
  width: 100%;
  height: auto;
}

.base-teaser__tags {
  margin: 1.2em 0 0.8em;
}

.base-teaser__title {
  @extend %text;
}

.base-teaser__excerpt {
  @extend %text-small;
  margin-top: -1rem;
}























@import "../scss/importall.scss";

.search-results__headline {
  @extend %h0;
  margin: 0 0 1.5em 0;

  span {
    color: var(--category-color);
  }
}

.search-results__results {
  padding-bottom: 10vw;

  > :not(:last-child) {
    margin-bottom: 1em;
  }
}

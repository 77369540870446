







































































.base-logo {
  display: flex;
}

.base-logo__bg {
  fill: var(--category-color);
}

.base-logo svg {
  width: 100%;
  height: 100%;
  color: #000;
  overflow: visible;
}







































@import "../scss/importall.scss";

.front-page__headline {
  @extend %h1;
  margin-top: 0;
  margin-bottom: 0.5em;
}

.front-page__subline {
  @extend %h3;
  margin-top: 0;
  margin-bottom: 1em;
}

.front-page__text {
  @extend %text-excerpt;
}

.front-page__content {
  padding: 6rem 0 0 0;
}






































@import "../scss/importall.scss";

.image-text {
  @extend %text;
  clear: both;
  margin: 4rem 0;
}

.image-text__image {
  margin-bottom: 1em;
}

.image-text__caption {
  @extend %text-small;
  padding-top: 1em;
}

.image-text--orientation-left .image-text__image {
  @include mq-tablet {
    width: 40%;
    margin-left: -5rem;
    margin-right: 3rem;
    float: left;
  }
}

.image-text--orientation-right .image-text__image {
  @include mq-tablet {
    width: 40%;
    margin-right: -5rem;
    margin-left: 3rem;
    float: right;
  }
}

// special case
.split-layout__content {
  .image-text--orientation-right .image-text__image {
    @include mq-tablet {
      margin-right: 0;
    }
  }

  .image-text--orientation-left .image-text__image {
    @include mq-tablet {
      margin-left: 0;
    }
  }
}












































































@import "../../scss/importall.scss";

.navigation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: #fff;
  z-index: 200;
  padding: 8rem 3rem 3rem 2rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  border-left: 1rem solid var(--category-color);

  @include mq-tablet {
    border-left: 2rem solid var(--category-color);
    padding: 8rem 48rem 3rem 4rem;
  }
}

.navigation-overlay__menu {
  > a {
    @extend %h1;

    text-decoration: none;
    display: block;
    color: inherit;
    transition: all 0.15s ease-in-out;

    &:hover {
      padding-left: 0.5rem;
    }
  }
}

.navigation-overlay__tags {
  padding: 4rem 0 0 0;
}

.navigation-overlay__address {
  @extend %text;
  padding: 3rem 0 0 0;

  a {
    color: inherit;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
    border-bottom: 0.1rem solid transparent;

    &:hover {
      border-color: currentColor;
    }
  }
}

.navigation-overlay__billingaddress {
  @extend %text;
  padding: 1rem 0 0 0;
}

.navigation-overlay__social {
  padding: 2rem 0 0 0;
}

.navigation-overlay__footer {
  padding: 5rem 0 0 0;
}

.navigation-overlay__meta {
  position: fixed;
  top: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  color: #000;
}

.navigation-overlay__close {
  width: 4rem;
  height: 4rem;
  top: 2rem;
  right: 2rem;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  @include mq-tablet {
    width: 3rem;
    height: 3rem;
  }

  &:hover {
    opacity: 0.6;
  }
}

.navigation-overlay__language {
  padding: 0.5rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}



















@import "../scss/importall.scss";

.text-content {
  @extend %scoped-content;
  margin: 4rem 0;

  &--narrow {
    margin: 1rem 0;
  }
}














@import "../scss/importall.scss";

.teaser-grid {
  @include flexgrid(6rem, 4rem, 1);

  @include mq-tablet {
    @include flexgrid(6rem, 4rem, 2);
  }
}

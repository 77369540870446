












@import "../scss/importall.scss";

.more-tags {
  padding: 4rem 0 1rem;
}

.more-tags__headline {
  @extend %text;

  margin: 0 0 2rem;
}






















































































@import "../scss/importall.scss";

.social-bar {
  display: flex;
  margin-left: -1.4rem;
  align-items: center;
}

.social-bar__link {
  display: block;
  width: 1.9rem;
  margin-left: 1.4rem;
  color: #000;

  svg {
    width: 100%;
    display: block;
    transition: all 0.25s ease-in-out;
  }

  &:hover svg {
    transform: translateY(-0.4rem);
  }
}








































@import "../scss/importall.scss";

.search-input {
  @extend %h1;
  outline: none;
  color: inherit;
  border-bottom: 0.05em solid currentColor;
  transition: opacity 0.15s ease-in-out;

  &:empty {
    opacity: 0.5;
  }

  &:empty:before {
    content: attr(placeholder);
    display: block; /* For Firefox */
    pointer-events: none;
  }
}

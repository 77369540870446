

















.icon-link {
  text-decoration: none;
  display: block;
  color: #000;
  width: 2.65rem;
}

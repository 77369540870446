@font-face {
  font-family: 'Baton Turbo';
  src: url('./fonts/BatonTurboWeb-Regular.eot');
  src: url('./fonts/BatonTurboWeb-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/BatonTurboWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Baton Turbo';
  src: url('./fonts/BatonTurboWeb-Bold.eot');
  src: url('./fonts/BatonTurboWeb-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/BatonTurboWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

body {
  .klaro .cookie-modal .cm-btn.cm-btn-success,
  .klaro .cookie-notice .cm-btn.cm-btn-success {
    background: #44c4af;
    color: #000;
    font-family: inherit;
    font-size: 1em;
  }

  .klaro .cookie-modal .cm-btn,
  .klaro .cookie-notice .cm-btn {
    border-radius: 0;
  }

  .klaro .cookie-modal a,
  .klaro .cookie-notice a {
    color: #44c4af;
  }

  .klaro .cookie-modal .cm-app-input:checked + .cm-app-label .slider,
  .klaro .cookie-notice .cm-app-input:checked + .cm-app-label .slider {
    background-color: #44c4af;
  }

  .klaro .cookie-modal .cm-modal {
    border-radius: 0;
  }

  .klaro .cookie-notice .cn-body p.cn-ok {
    display: flex;
    align-items: center;
  }
}

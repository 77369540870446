
















.search-icon {
  display: block;
}

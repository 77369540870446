

























































.responsive-img {
  width: 100%;
  display: block;
}

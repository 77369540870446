












@import "../scss/importall.scss";

.author-info {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding-right: 2rem;
}

.author-info__imagewrap {
  height: 4.8rem;
  display: flex;
  margin-left: -0.2rem;

  img {
    width: 4.8rem;
    height: 4.8rem;
    object-fit: cover;
    border-radius: 100%;
    border: 0.2rem solid #fff;

    &:not(:first-child) {
      margin-left: -1.5rem;
    }
  }
}

.author-info__content {
  @extend %text;

  margin-left: 1rem;
}

@import 'scss/importall';
@import 'scss/fonts';
@import 'scss/klaro';

:root {
  --category-color: #999;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-family: 'Baton Turbo', sans-serif;

  font-size: 2.5vw; // 414px: 1.55rem = 16px (roughly)

  @include mq-tablet {
    font-size: 1vw; // 1280px: 1.25rem = 16px
  }
}

body {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.app-wrap {
  position: relative;
  min-height: 100vh;
}

.raw-code {
  display: flex;
  justify-content: center;
  margin: 4rem 0;
}

.button-section {
  margin: 4rem 0;
}

[v-cloak] {
  display: none;
}

.v-scroll-reveal, .scroll-reveal {
  visibility: hidden;
}

// @see https://webaim.org/techniques/css/invisiblecontent/#skipnavlinks
.skip-link a {
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
  font-size: 2rem;
}

.skip-link a:focus {
  top: 4px;
  left: 4px;
  z-index: 100;
  width:auto;
  height:auto;
  padding: 2rem;
  background-color: #fff;
}



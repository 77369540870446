












.tag-inline-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 0 -1rem;

  > * {
    margin: 0 0 0 1rem;
  }
}

%ol-scoped {
  @extend %text;
  
  padding: 0;

  & {
    counter-reset: item;
  }

  li {
    display: block;
    position: relative;
  }

  li:before {
    content: counters(item, '.') ' ';
    counter-increment: item;
    position: absolute;
    left: 0;
  }

  > li {
    padding-left: 1em;

    ol > li {
      padding-left: 2em;

      ol > li {
        padding-left: 3em;

        ol > li {
          padding-left: 4em;
        }
      }
    }
  }
}

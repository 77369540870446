





























































































@import "../scss/importall.scss";

.blog-layout {
  padding: 30vw 3rem 3rem 3rem;

  @include mq-tablet {
    padding: 15vw 3rem 3rem 3rem;
  }
}

.blog-layout__logo {
  position: fixed;
  top: 3rem;
  left: 3rem;
  width: 12rem;
  display: flex;
  z-index: 10;

  @include mq-tablet {
    left: 6rem;
  }
}

.blog-layout__head {
  position: relative;
  margin: 0 -3rem;
}

.blog-layout__head-container {
  margin: 0 auto;
  padding: 0 3rem;

  @include mq-tablet {
    padding: 0;
    width: 65vw;
  }
}

.blog-layout__headline {
  @extend %h0;
  position: relative;
  margin: 0;
  padding: 1em 0;
  z-index: 1;
}

.blog-layout__image {
  position: relative;
  z-index: 1;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.blog-layout__block {
  position: absolute;
  top: 0;
  height: 100%;
  width: 30vw;
  background: var(--category-color);
}

.blog-layout__container {
  margin: 0 auto;

  @include mq-tablet {
    width: 65vw;
  }
}

.blog-layout__meta {
  @extend %text;
  padding: 3em 0;
  margin-top: -2em;

  > * {
    margin-top: 2em;
  }

  @include mq-tablet {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tag-box-list {
      justify-content: flex-end;
    }
  }
}

.blog-layout__excerpt {
  @extend %text-excerpt;

  padding: 0 0 3em 0;
}

.blog-layout__content-container {
  @include mq-tablet {
    padding: 0 5rem;
  }
}

.blog-layout__content {
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.blog-layout__topmeta {
  position: absolute;
  z-index: 100;
  top: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  color: #000;
  position: fixed;
}

.blog-layout__burger {
  padding: 1rem;
  color: inherit;
  cursor: pointer;
  width: 5rem;
  height: 5rem;
  transition: all 0.15s ease-in-out;

  &:hover {
    opacity: 0.6;
  }

  @include mq-tablet {
    width: 4rem;
    height: 4rem;
  }
}

.blog-layout__newsletter {
  padding-top: 5rem;
}

.blog-layout__podcast {
  padding-top: 5rem;

  &:empty {
    display: none;
  }
}

.blog-layout__social {
  padding-top: 5rem;
}

.blog-layout--has-toc {
  padding-left: 5rem;

  .blog-layout__logo {
    left: 5rem;

    @include mq-tablet {
      left: 6rem;
    }
  }
}

@import 'typo';

%scoped-content {
  @extend %text;

  h1 {
    @extend %h1;
    margin: 2em 0 .5em 0;
  }

  h2 {
    @extend %h2;
    margin: 2em 0 .5em 0;
  }

  h3,
  h4,
  h5,
  h6 {
    @extend %h3;
    margin: 2em 0 .5em 0;
  }

  p {
    margin: 0 0 1em 0;
  }

  p:first-child {
    margin-top: 0;
  }

  ol {
    @extend %ol-scoped;
  }

  li {
    margin: 1em 0;
  }

  a {
    color: #44c4af;
    color: var(--category-color);
    text-decoration: none;
    transition: all 0.15s ease-in-out;
    border-bottom: 0.1rem solid transparent;

    &:hover {
      border-color: currentColor;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  figure {
    margin: 2rem 0;
  }

  blockquote {
    margin: 1em 0;
    font-weight: 700;
    border-left: .2rem solid var(--category-color); 
    padding-left: 1em;
    font-style: italic;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}
















.tag-box-list {
  display: flex;
  flex-wrap: wrap;
  margin: -1vw 0 0 -1vw;

  > * {
    margin: 1vw 0 0 1vw;
  }
}



















































@import "../scss/importall.scss";

.toggle-content {
  @extend %scoped-content;
  margin: 4rem 0;
}

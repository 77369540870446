
























@import "../scss/importall.scss";

.top-menu {
  display: flex;
  align-items: center;
  user-select: none;
}

.top-menu__item {
  padding: 0.5rem;
  color: inherit;
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  transition: all 0.15s ease-in-out;

  &:hover {
    opacity: 0.6;
  }

  @include mq-tablet {
    width: 3rem;
    height: 3rem;
  }
}

.top-menu__link {
  @extend %text;
  margin-right: 0.5rem;

  color: inherit;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  border-bottom: 0.1rem solid transparent;

  &:hover {
    border-color: currentColor;
  }
}






























@import "../scss/importall.scss";

.tag-inline {
  @extend %text-small;

  display: flex;
  align-items: center;
}

.tag-inline__box {
  width: 0.5em;
  height: 0.5em;
  display: block;
  margin-right: 0.5em;
}

.tag-inline__tag {
  @extend %text-small;
}







































@import "../scss/importall.scss";

.base-button {
  @extend %text;
  display: inline-block;
  background: #000;
  background: var(--category-color);
  cursor: pointer;
  text-decoration: none;
  transition: all 0.15s ease-in-out;

  &:hover {
    opacity: 0.9;
  }

  &--wide {
    width: 100%;
  }
}

.base-button__wrap {
  display: flex;
  align-items: center;
  padding: 0.5em 1.2em;
  color: #fff;
}

.base-button__icon {
  width: 1.6rem;
  margin-right: 1rem;
  transition: transform 300ms;

  .base-button--icon-rotate & {
    transform: rotate(45deg);
  }

  svg {
    display: block;
    width: 100%;
  }
}








@import "../scss/importall.scss";

.language-switch {
  @extend %text;

  a {
    color: inherit;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
    border-bottom: 0.1rem solid transparent;

    &:hover {
      border-color: currentColor;
    }
  }
}
